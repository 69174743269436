<template>
  <div>
    <section class="hero hero-post">
      <div class="container"></div>
    </section>

    <section class="index-section s-post">
      <div class="container">
        <div class="layout-media display--grid">
          <div class="layout-main s-post--entry">
            <div class="post-back btn-action display--flex">
              <router-link to="/media-center" class="btn btn-o btn-sm"
                >Вернуться в Медиа-центр</router-link
              >
            </div>
            <div v-if="singleNews">
              <h1 class="post-title">{{ singleNews.title }}</h1>
              <!-- <div class="post-share display--flex">Поделиться</div> -->
              <div class="post-tags display--flex">
                <span v-for="tag of singleNews.tags.data" :key="tag.id">{{
                  tag.title
                }}</span>
              </div>
              <div class="post-thumbnail">
                <img :src="`${singleNews.full_image}`" :alt="singleNews.title" />
              </div>
              <div class="post-content" v-html="singleNews.text"></div>
              <div class="post-date">{{ singleNews.created_at }}</div>
              <div class="post-date">Автор: {{ singleNews.author.name }}</div>
            </div>
          </div>
          <aside class="layout-aside">
            <story-list-block></story-list-block>
          </aside>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { computed, onBeforeMount } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import StoryListBlock from "../../components/story-list-block/StoryListBlock.vue";
import { useStore } from "vuex";
import { useHead } from '@vueuse/head';
export default {
  components: { StoryListBlock },
  name: "news-page",
  inject: ["backUrl"],
  setup() {
    const route = useRoute(),
      store = useStore();

    const singleNews = computed(() => store.state.news.singleNews);
    useHead({
      title: computed(() => (singleNews.value ? singleNews.value.title : "")),
      meta: [
        {
          property: "og:description",
          content: computed(() =>
            singleNews.value ? singleNews.value.short_text : ""
          ),
        },
      ],
    });

    onBeforeMount(() => {
      store.dispatch("news/getSingleNews", route.params.slug);
    });

    return {
      singleNews,
    };
  },
};
</script>

<style scoped>
.s-post--entry .post-back {
  margin-bottom: 22px;
}

.s-post--entry .post-share {
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 10px 0;
}

.s-post--entry .post-title {
  margin: 0 0 15px 0;
  font-size: 46px;
  font-weight: 300;
  line-height: 51px;
  letter-spacing: -0.02em;
  text-align: left;
}

.s-post--entry .post-intro {
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 39px;
  text-align: left;
}

.post-title {
  color: var(--color);
  text-decoration: none;
}

.post-content {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0;
  text-align: left;
  word-break: break-word;
}

.post-content p,
.post-content ul,
.post-content ol {
  margin: 0 0 10px;
}

.post-content p + ul,
.post-content p + ol,
.post-content ul + ul,
.post-content ul + ol,
.post-content ol + ul,
.post-content ol + ol {
  margin-bottom: 20px;
}

.post-content ul {
  padding: 0;
  list-style: none;
}

.post-content ul > li {
  position: relative;
  margin-bottom: 12px;
  padding-left: 30px;
}

.post-content ul > li::before {
  position: absolute;
  display: block;
  content: "";
  top: 14px;
  left: 0;
  width: 20px;
  height: 1px;
  background: var(--accent);
}

.post-content ol > li {
  position: relative;
  margin-bottom: 12px;
}

.s-post--entry .post-date {
  margin-top: 20px;
  color: var(--color-light-2);
  font-size: 16px;
  line-height: 19px;
}

.post-thumbnail {
  display: block;
}

.post-thumbnail img {
  display: block;
  width: 100%;
  object-fit: cover;
}

a.post-thumbnail:hover {
  opacity: 0.7;
}

.post-lg .post-thumbnail,
.s-post--entry .post-thumbnail {
  margin: 0 0 20px 0;
}

.post-lg .post-thumbnail img,
.s-post--entry .post-thumbnail img {
  height: 380px;
  border-radius: 10px;
}

.post-tags {
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 15px 0;
}

.post-tags span {
  display: inline-block;
  margin: 8px 8px 0 0;
  padding: 3px 7px 4px;
  color: var(--color);
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  box-shadow: none;
  font-size: 13px;
  font-weight: 300;
  line-height: 1em;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
}

.post-tags span.tag-accent {
  color: #ffffff;
  background-color: var(--accent);
  border-color: var(--accent);
}

@media (max-width: 767px) {
  .s-post--entry .post-title {
    font-size: 36px;
    line-height: 43px;
  }

  .s-post--entry .post-intro {
    font-size: 24px;
    line-height: 29px;
  }

  .post-content {
    font-size: 18px;
    line-height: 22px;
  }
}
</style>